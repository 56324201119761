<script>
    // -- IMPORTS

    import { onMount } from 'svelte';
    import { Router, Route } from 'svelte-routing';
    import { defineDualTag, defineLineTag, defineTag, setTextBySlug } from 'senselogic-gist';
    import { messagingService } from '$src/lib/messaging_service.js';
    import { getUpdatedLanguageTag } from '$lib/language';
    import { webSocketUrl, fetchData } from '$lib/base.js';
    import { ensureLanguageTagInPath, currentPathname, currentRoutePath } from '$lib/router';
    import { cityArrayStore } from '$store/cityArrayStore';
    import { countryArrayStore } from '$store/countryArrayStore';
    import { featureTypeByCategoryAndSubCategoryArrayStore } from '$store/featureTypeByCategoryAndSubCategoryArrayStore';
    import { isLoadingProfile, profileSignedInStore } from '$store/profileStore';
    import { languageArrayStore } from '$store/languageArrayStore';
    import { languageTagArrayStore } from '$store/languageTagArrayStore';
    import { languageTagStore } from '$store/languageTagStore';
    import { navigationAdjustedStore } from '$store/navigationStore';
    import { propertyTypeArrayStore } from '$store/propertyTypeArrayStore';
    import { rentalTypeArrayStore } from '$store/rentalTypeArrayStore';
    import { urlParamsStore } from '$store/urlParamsStore';
    import { notificationCenterStore } from '$store/notificationCenterStore.js';
    import AdLayout from '$component/layout/AdLayout.svelte';
    import BodyOverflow from '$component/layout/BodyOverflow.svelte';
    import BookingsLayout from '$component/layout/BookingsLayout.svelte';
    import DashboardRouterWrapper from '$component/layout/DashboardRouterWrapper.svelte';
    import Loading from '$component/layout/Loading.svelte';
    import { webSocket } from '$store/webSocketStore';
    import { genderArrayStore, genderByIdMapStore } from './lib/store/genderStore';
    import RouteWithMenu from './lib/component/layout/RouteWithMenu.svelte';
    import Menu from './lib/component/layout/Menu.svelte';

    // -- VARIABLES

    export let url = '';
    let isLoading = true;
    let termType;
    let typeId;
    let arrivalDate;
    let departureDate;
    let cityId;
    let countryCode;
    let selectedCity;
    let selectedCountry;
    let isMounted = false;
    let hash = window.location.hash;
    let hashParams = new URLSearchParams( hash.substring( 1 ) );
    let googleAccessToken = hashParams.get( 'access_token' );
    let webSocketReconnectTimeout;

    // -- FUNCTIONS

    function setupWebSocket(
        )
    {
        try
        {
            if ( webSocketReconnectTimeout )
            {
                clearTimeout( webSocketReconnectTimeout );
            }

            if ( $webSocket && !$profileSignedInStore )
            {
                $webSocket.close();
            }

            $webSocket = new WebSocket( webSocketUrl );

            $webSocket.onopen = function(
                )
            {
                if ( $profileSignedInStore )
                {
                    $webSocket.send(
                        JSON.stringify(
                            {
                                type: 'registerUser',
                                userId: $profileSignedInStore.userId
                            }
                            )
                        );
                }
                else
                {
                    webSocketReconnectTimeout = setTimeout( setupWebSocket, 1000 );
                }
            };

            $webSocket.onmessage = function(
                e
                )
            {
                let data = JSON.parse( e.data );

                if ( data.type === 'addNotification' )
                {
                    notificationCenterStore.add( data.notification );
                }
            };

            $webSocket.onerror = function(
                error
                )
            {
                if ( $webSocket )
                {
                    $webSocket.onclose = function(
                        )
                    {
                        webSocketReconnectTimeout = setTimeout( setupWebSocket, 3000 );
                    }

                    $webSocket.close();
                }
                else
                {
                    clearTimeout( webSocketReconnectTimeout );
                }
            };

            $webSocket.onclose = function clear(
                )
            {
                webSocketReconnectTimeout = setTimeout( setupWebSocket, 10000 );
            }
        }
        catch ( error )
        {
            console.error( error );
            webSocketReconnectTimeout = setTimeout( setupWebSocket, 3000 );
        }
    }

    // ~~

    async function loadData(
        )
    {
        try
        {
            let urlParams = new URLSearchParams( window.location.search );

            termType = urlParams.get( 'termType' );
            typeId = urlParams.get( 'typeId' );
            arrivalDate = urlParams.get( 'arrivalDate' );
            departureDate = urlParams.get( 'departureDate' );
            cityId = urlParams.get( 'cityId' );
            countryCode = urlParams.get( 'countryCode' )?.toUpperCase() ?? null;

            let selectedCityPromise
                = fetchData(
                    '/api/city/get-by-id',
                    {
                        method: 'POST',
                        body: JSON.stringify( { cityId, type: 'searchById' } ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );

            let selectedCountryPromise
                = fetchData(
                    '/api/country/get-by-code',
                    {
                        method: 'POST',
                        body: JSON.stringify( { countryCode, type: 'searchByCode' } ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );

            let [ selectedCityResponse, selectedCountryResponse ] = await Promise.all( [ selectedCityPromise, selectedCountryPromise ] );

            if ( selectedCityResponse )
            {
                selectedCity = selectedCityResponse.city;
            }

            if ( selectedCountryResponse )
            {
                selectedCountry = selectedCountryResponse.country;
            }
        }
        catch ( error )
        {
            selectedCity = selectedCity.city;
        }
    }

    // ~~

    async function loadNotification(
        )
    {
        let notificationResponse = await fetchData(
            '/api/notification',
            {
                method: 'POST',
                credentials : 'include'
            }
            );

        if ( notificationResponse )
        {
            notificationCenterStore.init( notificationResponse.notificationArray )
        }
    }

    // -- STATEMENTS

    onMount(
        async () =>
        {
            $isLoadingProfile = true;
            try
            {
                if ( googleAccessToken )
                {
                    let googleAuthProfile
                        = await fetchData(
                            '/api/google-auth?' + hash.substring( 1 ),
                            {
                                method: 'POST',
                                credentials: 'include'
                            }
                            );

                    $profileSignedInStore = googleAuthProfile;
                }
                else
                {
                    let sessionData
                        = await fetchData(
                            '/api/get-session',
                            {
                                method: 'POST',
                                credentials: 'include'
                            }
                            );

                    $profileSignedInStore = sessionData;
                }

                let data = await fetchData( '/api/page/home', { method: 'POST' } );
                let textArray = data.textArray;

                for ( let index = 0; index < textArray.length; index += 1 )
                {
                    setTextBySlug( textArray[ index ].text, textArray[ index ].slug );
                }

                countryArrayStore.set( data.countryArray );
                languageArrayStore.set( data.languageArray );
                languageTagArrayStore.set( data.languageTagArray );
                languageTagStore.set( getUpdatedLanguageTag( $languageTagArrayStore ) );
                ensureLanguageTagInPath( window.location, $languageTagStore );
                cityArrayStore.set( data.cityArray );
                propertyTypeArrayStore.set( data.propertyTypeArray );
                rentalTypeArrayStore.set( data.rentalTypeArray );
                featureTypeByCategoryAndSubCategoryArrayStore.set( data.featureTypeByCategoryAndSubCategoryArray );
                genderArrayStore.set( data.genderArray );
                genderByIdMapStore.set( data.genderByIdMap );
            }
            catch ( error )
            {
                console.error( 'Error :', error );
            }
            finally
            {
                isLoading = false;
                isMounted = true;
                $isLoadingProfile = false;
            }
        }
    );

    // ~~

    $: if ( $profileSignedInStore )
    {
        messagingService.initPush( $profileSignedInStore );
        setupWebSocket();
        loadNotification();
    }

    // ~~

    $: if ( isMounted )
    {
        $currentPathname, ensureLanguageTagInPath( window.location, $languageTagStore );
        $urlParamsStore, loadData();
    }

    // ~~

    defineLineTag( '! ', '<div class="paragraph title-1">', '</div>' );
    defineLineTag( '!! ', '<div class="paragraph title-2">', '</div>' );
    defineLineTag( '!!! ', '<div class="paragraph title-3">', '</div>' );
    defineLineTag( '!!!! ', '<div class="paragraph title-4">', '</div>' );
    defineLineTag( '- ', '<div class="paragraph dash-1">', '</div>' );
    defineLineTag( '  - ', '<div class="paragraph dash-2">', '</div>' );
    defineLineTag( '    - ', '<div class="paragraph dash-3">', '</div>' );
    defineLineTag( '      - ', '<div class="paragraph dash-4">', '</div>' );
    defineLineTag( '* ', '<div class="paragraph bullet-1">', '</div>' );
    defineLineTag( '  * ', '<div class="paragraph bullet-2">', '</div>' );
    defineLineTag( '    * ', '<div class="paragraph bullet-3">', '</div>' );
    defineLineTag( '      * ', '<div class="paragraph bullet-4">', '</div>' );
    defineLineTag( '', '<div class="paragraph">', '</div>' );

    defineDualTag( '**', '<span class="font-weight-700">', '</span>' );
    defineDualTag( '%%', '<i>', '</i>' );
    defineDualTag( '__', '<u>', '</u>' );
    defineDualTag( ',,', '<sub>', '</sub>' );
    defineDualTag( '^^', '<sup>', '</sup>' );

    defineTag( '~', '&nbsp;' );
    defineTag( '¦', '<wbr/>' );
    defineTag( '§', '<br/>' );
    defineTag( '¶', '<br class="linebreak"/>' );
    defineTag( '((', '<a class="link" href="' );
    defineTag( ')(', '" target="_blank">' );
    defineTag( '))', '</a>' );

</script>

<style lang="stylus">
    // -- IMPORTS

    @import 'constant.styl';
    @import 'mixin.styl';

    // -- ELEMENTS

    *
    {
        font-family: 'Plus Jakarta Sans', sans-serif;
    }

    // -- CLASSES

    div
    {
        position: relative;

        overflow-y: auto;
        margin-top: 4rem;
        height: calc( 100dvh - 4rem );

        +media( desktop )
        {
            margin-top: headerHeight;
            height: calc( 100dvh - 4.5rem );
        }
    }
</style>

<svelte:head>
    <html lang={ $languageTagStore } />
</svelte:head>

{ #if isLoading && navigationAdjustedStore }
    <Loading/>
{ :else }
    <Router { url }>
        <nav>
            { #await import( '$component/layout/Header.svelte' ) then { default: Header } }
                <Header
                    selectedCity={ selectedCity }
                    selectedCountry={ selectedCountry }
                    arrivalDate={ arrivalDate }
                    departureDate={ departureDate }
                />
            { /await }
        </nav>
        <div>
            <Route path='/:languageTag/*'>
                <Router basepath=':/languageTag'>
                    <Route path='/'>
                        { #await import( '$lib/page/HomePage.svelte' ) then { default: HomePage } }
                            <HomePage/>
                        { /await }
                    </Route>
                    <Route path='/blog/*'>
                        <Router basepath='/blog'>
                            <Route>
                                { #await import( '$lib/page/BlogPage.svelte' ) then { default: BlogPage } }
                                    <BlogPage/>
                                { /await }
                            </Route>
                            <Route path='/article/:id' let:params>
                                { #await import( '$lib/page/ArticlePage.svelte' ) then { default: ArticlePage } }
                                    <ArticlePage
                                        id='{ params.id }'
                                    />
                                { /await }
                            </Route>
                        </Router>
                    </Route>
                    <Route path='/privacy-policy'>
                        { #await import( '$lib/page/PrivacyPolicyPage.svelte' ) then { default: PrivacyPolicyPage } }
                            <PrivacyPolicyPage/>
                        { /await }
                    </Route>
                    <Route path='/terms'>
                        { #await import( '$lib/page/LegalTermsPage.svelte' ) then { default: LegalTermsPage } }
                            <LegalTermsPage/>
                        { /await }
                    </Route>
                    <Route path='/about'>
                        { #await import( '$lib/page/AboutPage.svelte' ) then { default: AboutPage } }
                            <AboutPage/>
                        { /await }
                    </Route>
                    <RouteWithMenu
                        path='/search'
                        component={ () => import( '$lib/page/PropertiesPage.svelte' ) }
                        componentParams=
                        {
                            {
                                selectedCity,
                                selectedCountry,
                                arrivalDate,
                                departureDate
                            }
                        }
                    />
                    <RouteWithMenu path='/property/:id' component={ () => import( '$lib/page/PropertyPage.svelte' ) }/>
                    <RouteWithMenu path='/profile/:id' component={ () => import( '$lib/page/ProfilePage.svelte' ) }/>
                    <Route path='/dashboard/*'>
                        <DashboardRouterWrapper>
                            <Menu/>
                            <Router basepath='/dashboard'>
                                <Route>
                                    { #await import( '$src/lib/page/DashboardPage.svelte' ) then { default: DashboardPage } }
                                        <DashboardPage/>
                                    { /await }
                                </Route>
                                <Route path='/favorites'>
                                    { #await import( '$lib/page/FavoritesPage.svelte' ) then { default: FavoritesPage } }
                                        <FavoritesPage
                                            selectedCity={ selectedCity }
                                            selectedCountry={ selectedCountry }
                                        />
                                    { /await }
                                </Route>
                                <Route path='/history'>
                                    <div class="color-black">History</div>
                                </Route>
                                <Route path='/invoices'>
                                    { #await import ('$lib/page/InvoicePage.svelte' ) then { default: InvoicePage } }
                                        <InvoicePage/>
                                    { /await }
                                </Route>
                                <Route path='/personal-details'>
                                    { #await import ('$lib/page/PersonalDetailsPage.svelte' ) then { default: PersonalDetailsPage } }
                                        <PersonalDetailsPage/>
                                    { /await }
                                </Route>
                                <Route path='/banking-information'>
                                    { #await import( '$lib/page/AccountBankingInformationPage.svelte' ) then { default: AccountBankingInformationPage } }
                                        <AccountBankingInformationPage/>
                                    { /await }
                                </Route>
                                <Route path='/statistics'>
                                    { #await import ('$lib/page/StatisticsPage.svelte' ) then { default: StatisticsPage } }
                                        <StatisticsPage/>
                                    { /await }
                                </Route>
                                <Route path='/settings'>
                                    { #await import ('$lib/page/SettingsPage.svelte' ) then { default: SettingsPage } }
                                        <SettingsPage/>
                                    { /await }
                                </Route>
                                <Route path='/notifications'>
                                    { #await import( '$lib/page/NotificationsPage.svelte' ) then { default: NotificationsPage } }
                                        <NotificationsPage/>
                                    { /await }
                                </Route>
                                <Route path='/faq'>
                                    { #await import( '$lib/page/FaqPage.svelte' ) then { default: FaqPage } }
                                        <FaqPage/>
                                    { /await }
                                </Route>
                                <Route path='/balance'>
                                    { #await import( '$lib/page/AccountBalancePage.svelte' ) then { default: AccountBalancePage } }
                                        <AccountBalancePage/>
                                    { /await }
                                </Route>
                                <Route path='/bookings'>
                                    { #await import( '$lib/page/BookingsPage.svelte' ) then { default: BookingsPage } }
                                        <BookingsLayout>
                                            <BookingsPage/>
                                        </BookingsLayout>
                                    { /await }
                                </Route>
                                <Route path='/rental-file'>
                                    { #await import( '$lib/page/RentalFilePage.svelte' ) then { default: RentalFilePage } }
                                        <RentalFilePage/>
                                    { /await }
                                </Route>
                                <Route path='/supporting-document'>
                                    { #await import( '$lib/page/SupportingDocumentPage.svelte' ) then { default: SupportingDocumentPage } }
                                        <SupportingDocumentPage/>
                                    { /await }
                                </Route>
                                <Route path='/ads/*'>
                                    <AdLayout>
                                        <Router basepath='/dashboard/ads'>
                                            <Route path='/'>
                                                { #await import( '$lib/page/AdsPage.svelte' ) then { default: AdsPage } }
                                                    <AdsPage/>
                                                { /await }
                                            </Route>
                                            <Route path='/new'>
                                                { #await import( '$src/lib/page/AdPage.svelte' ) then { default: AdPage } }
                                                    <AdPage/>
                                                { /await }
                                            </Route>
                                            <Route path='/:propertyId' let:params>
                                                { #await import( '$src/lib/page/AdPage.svelte' ) then { default: AdPage } }
                                                    <AdPage propertyId="{ params.propertyId }"/>
                                                { /await }
                                            </Route>
                                            <Route path='/availability'>
                                                { #await import( '$lib/page/AvailabilityPage.svelte' ) then { default: AvailabilityPage } }
                                                    <AvailabilityPage />
                                                { /await }
                                            </Route>
                                            <Route path='/ongoing-contracts'>
                                                { #await import( '$lib/page/OngoingContractsPage.svelte' ) then { default: OngoingContractsPage } }
                                                    <OngoingContractsPage />
                                                { /await }
                                            </Route>
                                            <Route path="/lease-contract/:id" let:params>
                                                { #await import( '$lib/page/LeaseContractPage.svelte' ) then { default: LeaseContractPage } }
                                                    <LeaseContractPage id={ params.id } />
                                                { /await }
                                            </Route>
                                            <Route path="/lease-contract/supporting-documents/:id" let:params>
                                                { #await import( '$lib/page/LeaseContractSupportingDocumentsPage.svelte' ) then { default: LeaseContractSupportingDocumentsPage } }
                                                    <LeaseContractSupportingDocumentsPage id={ params.id } />
                                                { /await }
                                            </Route>
                                            <Route path='/subscribe-services'>
                                                <div class="color-black">Subscribe services</div>
                                            </Route>
                                            { #await import ( '$lib/page/CurrentStays.svelte' ) then { default: CurrentStays } }
                                                <Route path='/current-stays'>
                                                    <CurrentStays id={ null } />
                                                </Route>
                                                <Route path='/current-stays/:id' let:params >
                                                    <CurrentStays id={ params.id } />
                                                </Route>
                                            { /await }
                                            <Route path='/completed-stays'>
                                                <div class="color-black">Completed stays</div>
                                            </Route>
                                        </Router>
                                    </AdLayout>
                                </Route>
                                <Route path='/help'>
                                    <div class="color-black">Help</div>
                                </Route>
                                <Route path='/conversation'>
                                    { #await import( '$src/lib/page/ConversationPage.svelte' ) then { default: ConversationPage } }
                                        <ConversationPage/>
                                    { /await }
                                </Route>
                                <Route path='/events'>
                                    { #await import( '$lib/page/EventsPage.svelte' ) then { default: EventsPage } }
                                        <EventsPage/>
                                    { /await }
                                </Route>
                                <Route path="/visit-request/:id" let:params>
                                    { #await import( '$src/lib/page/VisitRequestPage.svelte' ) then { default: VisitRequestPage } }
                                        <VisitRequestPage id="{ params.id }" />
                                    { /await }
                                </Route>
                                <Route path="/edit-lease-contract/:id" let:params>
                                    { #await import( '$lib/page/EditLeaseContractPage.svelte' ) then { default: EditLeaseContractPage } }
                                        <EditLeaseContractPage id={ params.id }/>
                                    { /await }
                                </Route>
                                <Route path="/rental-booking/:id" let:params>
                                    { #await import( '$lib/page/RentalBookingPage.svelte' ) then { default: RentalBookingPage } }
                                        <RentalBookingPage id={ params.id }/>
                                    { /await }
                                </Route>
                                <Route path='/property-management/*'>
                                    <Router basepath="/dashboard/property-management">
                                        <Route path='/:id' let:params >
                                            { #await import ( '$lib/page/PropertyManagementPage.svelte' ) then { default: PropertyManagementPage }  }
                                                <PropertyManagementPage id={ params.id } />
                                            { /await }
                                        </Route>
                                    </Router>
                                </Route>
                            </Router>
                        </DashboardRouterWrapper>
                    </Route>
                    <RouteWithMenu path="/booking/checkout/:id" component={ () => import( '$lib/page/CheckoutPage.svelte' ) }/>
                    <RouteWithMenu path="/confirm-booking-payment" component={ () => import( '$lib/page/ConfirmBookingPayment.svelte' ) }/>
                    <RouteWithMenu path="/auth/confirm-email-change" component={ () => import( '$lib/page/ConfirmEmailChange.svelte' ) }/>
                    <RouteWithMenu path="/auth/verify-sign-up" component={ () => import( '$lib/page/VerifySignUp.svelte' ) }/>
                    <RouteWithMenu path="/conversation/:propertyId/:propertyUserId/:conversationTypeId" component={ () => import( '$lib/page/ConversationHelperPage.svelte' ) }/>
                </Router>
            </Route>
        </div>
        { #await import( '$component/element/CookieConsent.svelte' ) then { default: CookieConsent } }
            <CookieConsent/>
        {/await }
    </Router>
    <BodyOverflow/>
{ /if }
